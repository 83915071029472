import React from "react";
import Midle from "./midle/_ordersBody";
import Header from "./header/header.jsx";
import "./_context.scss";
import "./_inputs.scss";
import "./_selectric.scss";
import "./_buttons.scss";
import "./_breadcrambs.scss";
import { Context } from "../_contex.jsx";

export default function Content() {
  return (
    <Context>
      <Header />
      <Midle />
    </Context>
  );
}
