

function ArrowPrev( props ) {
    return (
        <svg
            xmlns="img/arrow-prev.svg"
            viewBox="0 0 12 22"
            width="12px" height="22px">
            <path fillRule="evenodd" fill={props.colors}
                d="M11.535,1.878 L2.414,11.000 L11.535,20.121 C11.926,20.512 11.926,21.145 11.535,21.536 C11.145,21.926 10.512,21.926 10.121,21.536 L0.464,11.878 C0.225,11.639 0.150,11.311 0.205,11.000 C0.150,10.690 0.225,10.362 0.464,10.122 L10.121,0.464 C10.512,0.074 11.145,0.074 11.535,0.464 C11.926,0.854 11.926,1.488 11.535,1.878 Z" />
        </svg>
    )
}

function ArrowNext( props ) {
    return (
        <svg
            xmlns="img/ArrowNext"
            viewBox="0 0 12 22"
            width="12px" height="22px">
            <path fillRule="evenodd" fill={props.colors && props.colors}
                d="M11.536,11.878 L1.879,21.536 C1.488,21.926 0.855,21.926 0.465,21.536 C0.074,21.145 0.074,20.512 0.465,20.121 L9.586,11.000 L0.465,1.878 C0.074,1.488 0.074,0.854 0.465,0.464 C0.855,0.074 1.488,0.074 1.879,0.464 L11.536,10.122 C11.775,10.362 11.850,10.690 11.795,11.000 C11.850,11.311 11.775,11.639 11.536,11.878 Z" />
        </svg>

    )
}


export { ArrowPrev, ArrowNext}