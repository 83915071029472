


    export default function Preloader() {

        return (
            <div style={{margin: "2rem 47vw 2rem 48vw"}}>
            <img src="/preloader-main.gif" alt=""/>
            </div>
        )
    }