


  export default  function PaginationItem ({BigNumberPage, NumberPage, currentPage, paginete = Function.prototype, pageNumbers, endParametr}) {
    const number_page = (i, fls = true) => {
      return (
        <li
          key={`${i}as`}
          // при клике присваиват еласс активного элемента
          className={currentPage === i ? "page-item active" : "page-item"}
        >
          <span
            onClick={() => fls === true && paginete(i)} //получает значение страницы кликнутого элемента
            className="page-link"
          >
            {i}
          </span>
        </li>
      );
    };
    const itemNumberPage = (i) => {
      if (!endParametr) {
        if (currentPage <= 2 && i <= currentPage + 4) return number_page(i);

        if (currentPage >= 2 && currentPage - 2 <= i && i <= currentPage + 1)
          return number_page(i);

        if (currentPage <= pageNumbers.length - 3 && i === pageNumbers.length)
          return (
            <>
              {number_page("...", false)}
              {number_page(i)}
            </>
          );
      }
    };

    return (
      <>
        {BigNumberPage ? (
          <>{itemNumberPage(BigNumberPage)}</>
        ) : (
          !endParametr &&
          NumberPage && (
            <li
              // при клике присваиват еласс активного элемента
              className={
                currentPage === NumberPage ? "page-item active" : "page-item"
              }
            >
              <span
                onClick={() => paginete(NumberPage)} //получает значение страницы кликнутого элемента
                className="page-link"
              >
                {NumberPage}
              </span>
            </li>
          )
        )}
        {endParametr === "true" && (
          <>
            <li
              className={
                currentPage === NumberPage ? "page-item active" : "page-item"
              }
            >
              <span
                onClick={() => paginete(NumberPage)} //получает значение страницы кликнутого элемента
                className="page-link"
              >
                {NumberPage}
              </span>
            </li>
            <li
              className={
                currentPage === NumberPage ? "page-item active" : "page-item"
              }
            >
              <span className="page-link">...</span>
            </li>
          </>
        )}
      </>
    );
  }