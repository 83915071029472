import React, { useContext, useRef, useState } from "react";
import { CostomContext } from "../../_contex";
import InputFromRequest from "../Dropdown/inputSelect.jsx";
import "./_modalMask.scss";

export const ModalMask = ({ ExpenditureRef }) => {
  const {
    dataExeles,
    setMask = Function.prototype,
    maskCheck,
    setLoadModalMask,
    loadModalMask,
    setMaskCheck,
    dispatchExcelesMask = Function.prototype,
    CHANGE_EXPENDITURE,
    UPDATE_EXPENDITURE,
    REMOVE_EXPENDITURE,
    excelesMask,
  } = useContext(CostomContext);

  const [inputChange, setInputChange] = useState("");
  const selectAllRef = useRef();

  return (
    <>
      <div className="modalMask--inner"></div>
      <div className="modalMask--wrapper">
        <div className="modalMask--header">
          <span>Выбор статьи расходов</span>
          <span
            onClick={() => {
              dispatchExcelesMask({
                type: REMOVE_EXPENDITURE,
                firstState: dataExeles,
              });
              selectAllRef.current.checked = false;
            }}
          >
            Сбросить все настройки
          </span>
          <span
            onClick={() => setLoadModalMask(!loadModalMask)}
            className="btn btn--close"
          >
            Х
          </span>
        </div>
        <div className="modalMask--input">
          <div className="checkbox">
            <div>
              <input
                type="checkbox"
                name="checkUser"
                id="maskCheck"
                onChange={(e) => {
                  setMaskCheck(e.target.checked);
                }}
              />
              <label htmlFor="maskCheck">Показывать не активные</label>
            </div>
            <div>
              <input
                ref={selectAllRef}
                type="checkbox"
                name="checkUser"
                id="selectAll"
                onChange={(e) => {
                  dispatchExcelesMask({
                    type: UPDATE_EXPENDITURE,
                    data: excelesMask,
                    checkeds: e.target.checked
                  });
                }}
              />
              <label htmlFor="selectAll">Выбрать все</label>
            </div>
          </div>
          <div>
            <InputFromRequest setInputChange={setInputChange} />
          </div>
        </div>
        <div className="modalMask--data checkbox">
          {maskCheck &&
            excelesMask
              .filter((el) =>
                inputChange.length > 0
                  ? el.categorySTR
                      .toLocaleLowerCase()
                      .indexOf(inputChange.trim().toLocaleLowerCase()) >= 0
                  : true
              )
              .map((item, index) => (
                <div
                  key={"li" + index}
                  data-index={index + 1}
                  onClick={(e) => {
                    dispatchExcelesMask({
                      type: CHANGE_EXPENDITURE,
                      id: item.categoryId,
                    });
                  }}
                >
                  <input
                    type="checkbox"
                    id={item.dataId}
                    checked={item.checked ? "checked" : false}
                  />
                  <label>
                    {item.active != 2
                      ? "Неактивно " + item.categorySTR + ";"
                      : item.categorySTR + ";"}
                  </label>
                </div>
              ))}
          {!maskCheck &&
            excelesMask
              .filter((el) => {
                return el.active == 2;
              })
              .filter((el) =>
                inputChange.length > 0
                  ? el.categorySTR
                      .toLocaleLowerCase()
                      .indexOf(inputChange.trim().toLocaleLowerCase()) >= 0
                  : true
              )
              .map((item, index) => (
                <div
                  key={"li" + index}
                  data-index={index + 1}
                  onClick={(e) =>
                    dispatchExcelesMask({
                      type: CHANGE_EXPENDITURE,
                      id: item.categoryId,
                    })
                  }
                >
                  <input
                    type="checkbox"
                    id={item.dataId}
                    checked={item.checked ? "checked" : false}
                  />
                  <label htmlFor={item.dataId}>
                    {item.active == 2 && item.categorySTR + ";"}
                  </label>
                </div>
              ))}
        </div>
        <div className="modalMask--footer">
          <span
            onClick={() => setLoadModalMask(!loadModalMask)}
            className="btn btn-secondary"
          >
            Отмена
          </span>
          <span
            onClick={() => {
              const newMask = excelesMask.filter((el) => el.checked);
              setMask(
                newMask.map((el, index) => {
                  return {
                    id: index + 1,
                    data: el.categorySTR.trim(),
                    dataId: el.categoryId,
                    active: el.active,
                  };
                })
              );
              setTimeout(() => {
                setLoadModalMask(!loadModalMask);
                ExpenditureRef.current.scrollIntoView({
                  block: "center",
                  behavior: "smooth",
                });
              }, 750);
            }}
            className="btn btn-secondary"
          >
            Применить
          </span>
        </div>
      </div>
    </>
  );
};
