import React, { useContext, useRef } from "react";
import "./_ordersBody.scss";
import Alert from "../alert/_alert.jsx";
import { ModalMask } from "../Dropdown/_modalMask";
import "../_selectric.scss";
import Report from "../Report/report.jsx";
import ReportFile from "../Report/reportFile.jsx";
import { CostomContext } from "../../_contex.jsx";
import Pagination from "../../Pagination/_pagination";
import { Upp } from "../scrollUp/_upp";

export default function Midle() {
  const {
    DelItems = Function.prototype,
    NewItems = Function.prototype,
    setDateFrom = Function.prototype,
    setDateBy = Function.prototype,
    setIpCash = Function.prototype,
    obnovlenie = Function.prototype,
    Validation = Function.prototype,
    setStatusPay = Function.prototype,
    dateFromRef,
    dateByRef,
    paymentAmount,
    paymentAmountRef,
    description,
    IpCash,
    statusPay,
    paymentRecipient,
    reportRef,
    Win_width,
    isLoadingReport,
    isLoadReport,
    BtnRef,
    comparePeriodRef,
    setComparePeriod,
    comparePeriod,
    compareYearRef,
    compareMonthRef,
    compareWeekRef,
    compareDayRef,
    setCompareYear,
    setCompareDay,
    setCompareMonth,
    setCompareWeek,
    dats,
    loadModalMask,
    setLoadModalMask,
    mask,
    setMask,
    dispatchExcelesMask = Function.prototype,
    CHANGE_EXPENDITURE,
    dataGeneration = Function.prototype,
    setAlertText,
    setShow_on,
    compareYear_Data,
    compareMonth_Data,
    compareDay_Data,
    compareWeek_Data,
  } = useContext(CostomContext);

  const ExpenditureRef = useRef();
  const ScrollUpRef = useRef();

  return (
    <>
      <Alert />
      <Upp ScrollUpRef={ScrollUpRef} />
      <div ref={ScrollUpRef}></div>
      {loadModalMask && <ModalMask ExpenditureRef={ExpenditureRef} />}
      <div className="costItem_content">
        <div className="label--left">Форма поиска</div>
        <div style={{ marginBottom: "5vw" }}>
          <div className="label--sm">
            Отчет статей расходов по выбранным параметрам.
          </div>
          <div>
            <div className="inputFormRequest--items">
              {mask &&
                mask.map((item) => (
                  <span
                    onClick={() => {
                      const newMask = mask.filter((el) => el.id !== item.id);
                      setMask(newMask);
                      dispatchExcelesMask({
                        type: CHANGE_EXPENDITURE,
                        id: item.dataId,
                      });
                    }}
                    key={item.id}
                    className="inputFormRequest--item"
                  >
                    {item.data}&nbsp;<span>x</span>
                  </span>
                ))}
            </div>
            <div ref={ExpenditureRef}></div>
            <button
              onClick={() => setLoadModalMask(!loadModalMask)}
              className="btn btn-primary"
            >
              <span>{"Статьи расходов"}</span>
            </button>
          </div>
        </div>
        <div className="label--left label--left--load">
          Дополнительные параметры
          <div className={"item--add--open item--add"}>&#8249;</div>
        </div>
        <div>
          <div className="settings">
            <div className="settings--inputElement">
              <label className="label" htmlFor="dateFrom">
                Введите начальную дату платежа
              </label>
              <input
                ref={dateFromRef}
                type="date"
                className="input"
                placeholder="С"
                pattern=""
                name="dateFrom"
                id="dateFrom"
                onChange={(e) => {
                  if (e.target.value !== "")
                    setDateFrom(dateFromRef.current.value);
                }}
                onBlur={(e) => {
                  if (e.target.value !== "")
                    setDateFrom(dateFromRef.current.value);
                }}
              />
            </div>
            <div className="settings--inputElement">
              <label className="label" htmlFor="dateBy">
                Введите конечную дату платежа
              </label>
              <input
                ref={dateByRef}
                type="date"
                className="input"
                placeholder="По"
                name="dateBy"
                id="dateBy"
                onChange={(e) => {
                  if (e.target.value !== "") setDateBy(dateByRef.current.value);
                }}
                onBlur={(e) => {
                  if (e.target.value !== "") setDateBy(dateByRef.current.value);
                }}
              />
            </div>
            <div className="settings--inputElement">
              <label className="label" htmlFor="paymentAmount">
                Введите сумму платежа
              </label>
              <div className="inputFormRequest--items">
                {paymentAmount &&
                  paymentAmount.map((item) => (
                    <span key={item.id} className="inputFormRequest--item">
                      {item.data}&nbsp;
                      <span onClick={() => DelItems(item.id, "paymentAmount")}>
                        x
                      </span>
                    </span>
                  ))}
              </div>
              <input
                onBlur={(e) => {
                  if (
                    e.target.value !== " " &&
                    e.target.value.length > 1 &&
                    e.target.value !== ""
                  )
                    NewItems(e.target.value, e.target.name);
                  e.target.value = "";
                }}
                ref={paymentAmountRef}
                type="number"
                className="input"
                placeholder="Сумма"
                title="Стоимость"
                name="paymentAmount"
                id="paymentAmount"
                onChange={(e) => {
                  if (e.target.value.indexOf(";") > 0) {
                    NewItems(e.target.value.replace(";", ""), e.target.name);
                    e.target.value = "";
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    NewItems(e.target.value, e.target.name);
                    e.target.value = "";
                  }
                }}
              />
            </div>
            <div className="settings--inputElement">
              <label className="label" htmlFor="keywords">
                Введите ключевые слова, которые присутствуют в описании
              </label>
              <div className="inputFormRequest--items">
                {description &&
                  description.map((item) => (
                    <span key={item.id} className="inputFormRequest--item">
                      {item.data}&nbsp;
                      <span onClick={() => DelItems(item.id, "description")}>
                        x
                      </span>
                    </span>
                  ))}
              </div>
              <input
                onBlur={(e) => {
                  if (
                    e.target.value !== " " &&
                    e.target.value.length > 1 &&
                    e.target.value !== ""
                  )
                    NewItems(e.target.value, e.target.name);
                  e.target.value = "";
                }}
                type="text"
                className="input"
                placeholder="Для ввода нажмите <Enter>"
                title="Ключевые слова, вводить нажатием на 'Enter'"
                name="description"
                id="description"
                onChange={(e) => {
                  if (e.target.value.indexOf(";") > 0) {
                    NewItems(e.target.value.replace(";", ""), e.target.name);
                    e.target.value = "";
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    NewItems(e.target.value, e.target.name);
                    e.target.value = "";
                  }
                }}
              />
            </div>
            <div className="checkbox">
              <label className="label">Выберите тип платежа</label>
              <input
                type="checkbox"
                name="checkUser"
                id="check1"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setIpCash([
                      ...IpCash,
                      { id: IpCash.length + 1, data: "ИП" },
                    ]);
                  } else {
                    setIpCash(IpCash.filter((item) => item.data !== "ИП"));
                  }
                }}
              />
              <label htmlFor="check1">ИП</label>
              <input
                type="checkbox"
                name="checkUser"
                id="check2"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setIpCash([
                      ...IpCash,
                      { id: IpCash.length + 1, data: "Касса" },
                    ]);
                  } else {
                    setIpCash(IpCash.filter((item) => item.data !== "Касса"));
                  }
                }}
              />
              <label htmlFor="check2">Касса</label>
            </div>
            <div className="checkbox">
              <label className="label">Выберите статус платежа</label>
              <input
                type="checkbox"
                name="checkUser"
                id="check3"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setStatusPay([
                      ...statusPay,
                      { id: IpCash.length + 1, data: "Оплачен" },
                    ]);
                  } else {
                    setStatusPay(
                      statusPay.filter((item) => item.data !== "Оплачен")
                    );
                  }
                }}
              />
              <label htmlFor="check3">Оплачен</label>
              <input
                type="checkbox"
                name="checkUser"
                id="check4"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setStatusPay([
                      ...statusPay,
                      { id: IpCash.length + 1, data: "Отложен" },
                    ]);
                  } else {
                    setStatusPay(
                      statusPay.filter((item) => item.data !== "Отложен")
                    );
                  }
                }}
              />
              <label htmlFor="check4">Отложен</label>
              <input
                type="checkbox"
                name="checkUser"
                id="check5"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setStatusPay([
                      ...statusPay,
                      { id: IpCash.length + 1, data: "Отменен" },
                    ]);
                  } else {
                    setStatusPay(
                      statusPay.filter((item) => item.data !== "Отменен")
                    );
                  }
                }}
              />
              <label htmlFor="check5">Отменен</label>
            </div>
            <div className="settings--inputElement">
              <label className="label" htmlFor="keywords1">
                Введите ключевые слова, которые присутствуют в маске получателя
                платежа
              </label>
              <div className="inputFormRequest--items">
                {paymentRecipient &&
                  paymentRecipient.map((item) => (
                    <span key={item.id} className="inputFormRequest--item">
                      {item.data}&nbsp;
                      <span
                        onClick={() => DelItems(item.id, "paymentRecipient")}
                      >
                        x
                      </span>
                    </span>
                  ))}
              </div>
              <input
                onBlur={(e) => {
                  if (
                    e.target.value !== " " &&
                    e.target.value.length > 1 &&
                    e.target.value !== ""
                  )
                    NewItems(e.target.value, e.target.name);
                  e.target.value = "";
                }}
                type="text"
                className="input"
                placeholder="Для ввода нажмите <Enter>"
                title="Ключевые слова, вводить нажатием на 'Enter'"
                name="paymentRecipient"
                id="paymentRecipient"
                onChange={(e) => {
                  if (e.target.value.indexOf(";") > 0) {
                    NewItems(e.target.value.replace(";", ""), e.target.name);
                    e.target.value = "";
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    NewItems(e.target.value, e.target.name);
                    e.target.value = "";
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div></div>
        <div
          className="checkbox"
          style={!isLoadReport ? { opacity: 0 } : { opacity: 1 }}
        >
          <div>
            <input
              ref={comparePeriodRef}
              type="checkbox"
              name="checkUser"
              id="check10"
              onChange={(e) => {
                setComparePeriod(e.target.checked);
              }}
            />
            <label htmlFor="check10">Cравнивать по периодам</label>
          </div>
          {comparePeriod && dats.length > 0 && (
            <div className="comparePeriod">
              <input
                // onFocus={(e)=>e.target.checked = compareDay}
                ref={compareDayRef}
                type="checkbox"
                name="checkUser"
                id="check11"
                onChange={(e) => {
                  setCompareDay(e.target.checked);
                  if (e.target.checked) {
                    setTimeout(() => {
                      compareDayRef.current.checked = true;
                      compareWeekRef.current.checked = false;
                      compareMonthRef.current.checked = false;
                      compareYearRef.current.checked = false;
                      setAlertText(
                        "Ничего не нажимайте, процесс формирование данных"
                      );
                      setShow_on(true);
                      setTimeout(
                        dataGeneration(compareDay_Data).then(
                          setTimeout(() => {
                            setAlertText("Данные загружены");
                            setTimeout(setShow_on(false), 2000);
                          }, 3000)
                        ),
                        1500
                      );
                    }, 300);
                  }
                }}
              />
              <label htmlFor="check11">Cравнивать по дням</label>
              <input
                // onFocus={(e)=>e.target.checked = compareWeek}
                ref={compareWeekRef}
                type="checkbox"
                name="checkUser"
                id="check12"
                onChange={(e) => {
                  setCompareWeek(e.target.checked);
                  if (e.target.checked) {
                    setTimeout(() => {
                      compareWeekRef.current.checked = true;
                      compareDayRef.current.checked = false;
                      compareMonthRef.current.checked = false;
                      compareYearRef.current.checked = false;
                      setAlertText(
                        "Ничего не нажимайте, процесс формирование данных"
                      );
                      setShow_on(true);
                      setTimeout(
                        dataGeneration(compareWeek_Data).then(
                          setTimeout(() => {
                            setTimeout(setShow_on(false), 2000);
                          }, 3000)
                        ),
                        1500
                      );
                    }, 300);
                  }
                }}
              />
              <label htmlFor="check12">Cравнивать по неделям</label>
              <input
                // onFocus={(e)=>e.target.checked = compareMonth}
                ref={compareMonthRef}
                type="checkbox"
                name="checkUser"
                id="check13"
                onChange={(e) => {
                  setCompareMonth(e.target.checked);
                  if (e.target.checked) {
                    setTimeout(() => {
                      compareMonthRef.current.checked = true;
                      compareWeekRef.current.checked = false;
                      compareDayRef.current.checked = false;
                      compareYearRef.current.checked = false;
                      setAlertText(
                        "Ничего не нажимайте, процесс формирование данных"
                      );
                      setShow_on(true);
                      setTimeout(
                        dataGeneration(compareMonth_Data).then(
                          setTimeout(() => {
                            setAlertText("Данные загружены");
                            setTimeout(setShow_on(false), 2000);
                          }, 3000)
                        ),
                        1500
                      );
                    }, 300);
                  }
                }}
              />
              <label htmlFor="check13">Cравнивать по месяцам</label>
              <input
                // onFocus={(e)=>e.target.checked = compareYear}
                ref={compareYearRef}
                type="checkbox"
                name="checkUser"
                id="check14"
                onChange={(e) => {
                  setCompareYear(e.target.checked);
                  if (e.target.checked) {
                    setTimeout(() => {
                      compareYearRef.current.checked = true;
                      compareWeekRef.current.checked = false;
                      compareMonthRef.current.checked = false;
                      compareDayRef.current.checked = false;
                      setAlertText(
                        "Ничего не нажимайте, процесс формирование данных"
                      );
                      setShow_on(true);
                      setTimeout(
                        dataGeneration(compareYear_Data).then(
                          setTimeout(() => {
                            setAlertText("Данные загружены");
                            setTimeout(setShow_on(false), 2000);
                          }, 3000)
                        ),
                        1500
                      );
                    }, 300);
                  }
                }}
              />
              <label htmlFor="check14">Cравнивать по годам</label>
            </div>
          )}
        </div>
        <div className="label--left"></div>
        <div>
          <div>
            <button
              ref={BtnRef}
              onClick={() => {
                Validation();
                obnovlenie();
                BtnRef.current.scrollIntoView();
              }}
              style={{ marginBottom: "1rem" }}
              className="btn btn-primary"
            >
              <span>{"Сформировать таблицу"}</span>
            </button>
          </div>
        </div>
        <div></div>
        <div>{isLoadReport && <ReportFile key="repoFile" />}</div>
      </div>
      <div ref={reportRef}>
        {Win_width > 540 && isLoadingReport && (
          <>
            <Report key="repo" />
            {isLoadReport && <Pagination />}
          </>
        )}
      </div>
    </>
  );
}
