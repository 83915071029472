import React, { useState, useRef, useContext, useReducer } from "react";
import "./_selected.scss";
import { CostomContext } from "../../_contex.jsx";

export default function InputFromRequest({ setInputChange }) {
  const {
    dataExeles,
    NewItems = Function.prototype,
    mask,
    setMask = Function.prototype,
    maskCheck,
    dispatchExcelesMask = Function.prototype,
    CHANGE_EXPENDITURE,
    REMOVE_EXPENDITURE,
  } = useContext(CostomContext);

  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const inputFormRequestRef = useRef();
  const ulRef = useRef();

  return (
    <>
      <div
        tabIndex="-1"
        className={
          isOpenSelect
            ? " selectric-wrapper selectric-js-select-time selectric-belowselectric-open selectric-focus "
            : "selectric-wrapper selectric-js-select-time selectric-below"
        }
        // onBlur={() => setIsOpenSelect(false)}
      >
        <div className="inputFormRequest ">
          <input
            key="input"
            ref={inputFormRequestRef}
            type="text"
            autoComplete="off"
            className="input"
            placeholder="Статья расходов"
            name="inputFormRequest"
            onChange={(e) => {
              setInputChange(e.target.value);
              setIsOpenSelect(true);
              if (e.target.value.indexOf(";") > 0) {
                NewItems(
                  e.target.value.replace(";", "").replace("Неактивно ", ""),
                  e.target.name
                );
                e.target.value = "";
              }
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                NewItems(e.target.value, e.target.name);
                e.target.value = "";
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
