import React, { useContext } from "react";
import { CostomContext } from "../../_contex.jsx";
import ReactExport from "react-data-export";

export default function ReportFile() {
    const {
        dats_Two,
        Win_width,
        dataStatistik,
        compareData,
        compareDataColumns,
    } = useContext(CostomContext);
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExceleSheet;

    const DataSet = [
        {
            columns: [
                { title: "Получатель платежа" },
                { title: "Дата" },
                { title: "Сумма" },
                { title: "Описание" },
                { title: "Крайняя дата" },
                { title: "ИП/касса" },
                { title: "Сумма выплаты" },
                { title: "Статус платежа" },
                { title: "Статья расходов" },
            ],
            data: dats_Two.map((item) => [
                { value: item.payee ? item.payee : "Пусто" },
                { value: item.sheet_date.value },
                { value: item.amount ? item.amount : " " },
                { value: item.description ? item.description : " " },
                { value: item.deadline ? item.deadline : "  " },
                { value: item.account_cashdesk ? item.account_cashdesk : " " },
                { value: item.confirmed_amount ? item.confirmed_amount : " " },
                { value: item.status ? item.status : " " },
                { value: item.category ? item.category : " " },
            ]),
        },
    ];
    let columnsData = [];
    if (compareData.length > 0) {
        columnsData = [
            {
                title: "Название статьи / группы статей",
                style: { font: { sz: "11", bold: true } },
                widthPx: 160,
                width: { wch: 25 },
            },
            {
                title: "Общая сумма оплаченных строк",
                style: { font: { sz: "11", bold: true } },
                widthPx: 160,
                width: { wch: 25 },
            },
        ];
    }

    console.log(compareData);
    compareData.map((el) => {
        // if()
        columnsData.push({
            title: el.title,
            style: { font: { sz: "11" } },
            widthPx: 40,
            width: { wch: 12 },
        });
    });
    let DataAmount = [];
    compareDataColumns.map((el) => {
        let summ = 0;
        el.allamount.map((item) => (summ += item));
        DataAmount.push([el.item, summ, ...el.allamount]);
    });

    let PeriodStaticData = [
        {
            columns: [
                {
                    title: "Название статьи / группы статей",
                    style: { font: { sz: "11", bold: true } },
                    width: { wch: 40 },
                },
                {
                    title: "Общая сумма оплаченных",
                    style: { font: { sz: "11", bold: true } },
                    widthPx: 160,
                    width: { wch: 25 },
                },
            ],
            data: dataStatistik.map((item) => [
                item.upperLevel
                    ? { value: item.data, style: { font: { sz: "14", bold: true } } }
                    : {
                        value: item.data,
                        style: { font: { sz: "11", color: { rgb: "FF808080" } } },
                    },
                { value: item.amount, widthPx: 650 },
            ]),
        },
        {
            ySteps: 2,
            columns: columnsData,
            data: DataAmount,
        },
    ];
    const StatisticData = PeriodStaticData;

    return (
        <>
            <ExcelFile
                filename={"Lukino" + new Date().getTime()}
                element={
                    <input
                        style={
                            Win_width <= 540
                                ? { marginLeft: "2rem", top: "-5rem" }
                                : {
                                    cursor: "pointer",
                                    marginBottom: "3rem",
                                    border: "2px solid #85c469",
                                }
                        }
                        className="btn btn-secondary"
                        type="button"
                        value="Скачать таблицу"
                    />
                }
            >
                <ExcelSheet dataSet={DataSet} name="Таблица поиска бюджета" />
                <ExcelSheet
                    dataSet={StatisticData}
                    name="Общая статистика по статьям расходов"
                />
            </ExcelFile>
        </>
    );
}
