import "./_upp.scss"

export const Upp = ({ ScrollUpRef }) => {
    return (
      <div
        onClick={()=>ScrollUpRef.current.scrollIntoView({
          block: "center",
          behavior: "smooth",
        })}
        className="scroll--up"
      >
        &#8679;
      </div>
    );
};