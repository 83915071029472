import "./report.scss"
import React, {useRef, useContext} from 'react'
import {CostomContext} from "../../_contex.jsx"
import Preloader from "./_preloader.jsx"

export default function Report(props) {
    const {POST, isLoadReport, currentPage} = useContext( CostomContext )
    const reportRef = useRef()

    return (
        !isLoadReport  ? <Preloader /> : 
        <table className="report--table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Получатель платежа</th>
                    <th scope="col">Дата</th>
                    <th scope="col">Сумма</th>
                    <th scope="col">Описание</th>
                    <th scope="col">Крайняя дата</th>
                    <th scope="col">ИП/Касса</th>
                    <th scope="col">Статус платежа</th>
                    <th scope="col">Статья расходов</th>
                </tr>
            </thead>
         <tbody
            ref={reportRef}>
                {
                    POST.map((item,index) => (
                    <tr key={"tr"+index}>
                    <th scope="row">{(currentPage === 1) ? index+1 : ((currentPage-1)*100+index+1)}</th>
                    <td>{item.payee}</td>
                    <td>{item.sheet_date.value}</td>
                    <td>{item.confirmed_amount}</td>
                    <td>{item.description}</td>
                    <td>{item.deadline}</td>
                    <td>{item.account_cashdesk}</td>
                    <td>{item.status}</td>
                    <td>{item.category}</td>
                    </tr>
                    ))
                }
            </tbody>
        </table>
    )
}