export const CHANGE_EXPENDITURE = "CHANGE_EXPENDITURE";
export const REMOVE_EXPENDITURE = "REMOVE_EXPENDITURE";
export const ADD_EXPENDITURE = "ADD_EXPENDITURE";
export const UPDATE_EXPENDITURE = "UPDATE_EXPENDITURE";


const newExcelesMask = (state, id) => {
  const newState = state.map((item, index) => {
    if (item.categoryId === id) {
      return { ...item, checked: !item.checked };
    } else {
      return item;
    }
  });
  return newState;
};

export const itemOfExpenditure = (state, action) => {
  switch (action.type) {
    case CHANGE_EXPENDITURE:
      return newExcelesMask(state, action.id);
      case UPDATE_EXPENDITURE:
          const newState = action.data.map((el) => {
            return { ...el, checked: action.checkeds };
          });
          return newState;
    case ADD_EXPENDITURE:
          return action.elements;
      case REMOVE_EXPENDITURE:
          return action.firstState
    default:
      return state;
  }
};
