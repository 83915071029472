import React, {useContext} from "react"
import "./Pagination.scss"
import {CostomContext} from "../_contex.jsx"
import {ArrowPrev, ArrowNext} from "../img/_svg.jsx"
import PaginationItem from "./_paginationItem.jsx"

// Создание Пагинации данных заказов
// Прописываем получаемые значения за место props-ов
export default function Pagination() {
    const {postPerPage, dats, paginete, currentPage} = useContext( CostomContext )
    const pageNumbers = []
    const all_page = Math.ceil( dats.length / postPerPage ) // расчет количества страниц
    for( let i = 1;i <= all_page;i++ ) {
      // создает массив с нумерацией страниц добавляя последующее число в конец массива
      pageNumbers.push(i);
    }

    return (
      <ul key="ul1" className="pagination">
        <li
          key="pageOne"
          className="arrow_prev"
          onClick={() => paginete(currentPage - 1)}
          style={
            currentPage === 1
              ? { pointerEvents: "none" }
              : { pointerEvents: "all" }
          }
        >
          {
            <ArrowPrev
              colors={
                currentPage === 1 ? "rgb(207, 207, 207)" : "rgb(17, 17, 17)"
              }
            />
          }
        </li>
        {currentPage > 5 && (
          <PaginationItem
            endParametr="true"
            key="EndPagin"
            pageNumbers={pageNumbers}
            paginete={paginete}
            currentPage={currentPage}
            NumberPage={1}
          />
        )}
        {
          //вывод нумерации страниц
          pageNumbers.length >= 8
            ? pageNumbers.map((i) => (
                <PaginationItem
                  key={"namber_page-" + i}
                  paginete={paginete}
                  BigNumberPage={i}
                  pageNumbers={pageNumbers}
                  currentPage={currentPage}
                />
              ))
            : pageNumbers.map((i) => (
                <PaginationItem
                  key={i}
                  pageNumbers={pageNumbers}
                  paginete={paginete}
                  currentPage={currentPage}
                  NumberPage={i}
                />
              ))
        }
        <li
          key="pageTwo"
          className="arrow_next"
          onClick={() => paginete(currentPage + 1)}
          style={
            currentPage >= pageNumbers.length
              ? { pointerEvents: "none" }
              : { pointerEvents: "all" }
          }
        >
          <ArrowNext
            colors={
              currentPage === pageNumbers.length
                ? "rgb(207, 207, 207)"
                : "rgb(17, 17, 17)"
            }
          />
        </li>
      </ul>
    );
}