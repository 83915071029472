export const CHANGE_TABLE_BUDGET = 'CHANGE_TABLE_BUDGET';
export const REMOVE_TABLE_BUDGET = "REMOVE_TABLE_BUDGET";

const newTableBudget = (state, newdats) => {
    //доработать вывод добавив в самое первое поле ретурн все строки ранее
    const newStatics = [];
    console.log(newdats)
    newdats.map(item => {
        if (!!item && typeof item === "string") {
            const items1 = item.split(" / ");
            //проверить фильтр
            newStatics[newStatics.length + 1] = {
                data: items1[0],
                upperLevel: true,
            };
            if (items1[1] !== undefined && items1[1] !== null)
                newStatics[newStatics.length + 1] = {
                    data: items1[0] + " / " + items1[1],
                };
            if (items1[2] !== undefined && items1[2] !== null)
                newStatics[newStatics.length + 1] = {
                    data: items1[0] + " / " + items1[1] + " / " + items1[2],
                };
            if (items1[3] !== undefined && items1[3] !== null)
                newStatics[newStatics.length + 1] = {
                    data: items1[0] +
                        " / " +
                        items1[1] +
                        " / " +
                        items1[2] +
                        " / " +
                        items1[3],
                };
            if (items1[4] !== undefined && items1[4] !== null)
                newStatics[newStatics.length + 1] = {
                    data: items1[0] +
                        " / " +
                        items1[1] +
                        " / " +
                        items1[2] +
                        " / " +
                        items1[3] +
                        " / " +
                        items1[4],
                };
            if (items1[5] !== undefined && items1[5] !== null)
                newStatics[newStatics.length + 1] = {
                    data: items1[0] +
                        " / " +
                        items1[1] +
                        " / " +
                        items1[2] +
                        " / " +
                        items1[3] +
                        " / " +
                        items1[4] +
                        " / " +
                        items1[5],
                };
            if (items1[6] !== undefined && items1[6] !== null)
                newStatics[newStatics.length + 1] = {
                    data: items1[0] +
                        " / " +
                        items1[1] +
                        " / " +
                        items1[2] +
                        " / " +
                        items1[3] +
                        " / " +
                        items1[4] +
                        " / " +
                        items1[5] +
                        " / " +
                        items1[6],
                };
            if (items1[7] !== undefined && items1[7] !== null)
                newStatics[newStatics.length + 1] = {
                    data: items1[0] +
                        " / " +
                        items1[1] +
                        " / " +
                        items1[2] +
                        " / " +
                        items1[3] +
                        " / " +
                        items1[4] +
                        " / " +
                        items1[5] +
                        " / " +
                        items1[6] +
                        " / " +
                        items1[7],
                };
        }
        // else if (!!item && typeof item == 'number') {
        //     newStatics[newStatics.length + 1] = {
        //         data: "Категория неизвестная. №" + item,
        //         upperLevel: true,
        //     };
        // }
        // // return null;
    });
    console.log("newStatics: ", newStatics);
    const newdata = [...newStatics].filter((el) => el !== undefined);
    let returndata = [];
    newdata.map((el) => {
        if (returndata.filter((elem) => elem.data === el.data).length == 0) {
            returndata[returndata.length + 1] = {...el };
        }
        // return null;
    });
    return {
        ...state,
        data: returndata
            .filter((el) => el !== null)
            .sort((a, b) => {
                if (a.data < b.data) {
                    return -1;
                }
                if (a.data > b.data) {
                    return 1;
                }
                return 0;
            })
    }
};

export const tableBudget = (state, action) => {
    switch (action.type) {
        case CHANGE_TABLE_BUDGET:
            return newTableBudget(state, action.newdats);
        case REMOVE_TABLE_BUDGET:
            return {...state, data: [] };
        default:
            return {...state, data: [] };
    }
}