import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { CHANGE_TABLE_BUDGET, tableBudget } from "./reducers/_tableBudget.js";
import {
  itemOfExpenditure,
  CHANGE_EXPENDITURE,
  REMOVE_EXPENDITURE,
  ADD_EXPENDITURE,
  UPDATE_EXPENDITURE,
} from "./reducers/_ExcelesMask.js";

export const CostomContext = createContext();

export const Context = (props) => {
  const [currentPage, setcurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(100);
  const IndexofLastPost = currentPage * postPerPage; // последний пост
  const IndexofFirstPost = IndexofLastPost - postPerPage; // Первый пост

  //----Константы
  const toDay = new Date();
  // ----------------------------------------------

  //Переменные Алерт
  const [AlertText, setAlertText] = useState("");
  const [show_on, setShow_on] = useState(false);
  const [tops, setTops] = useState("5rem");
  // ----------------------------------------------

  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [loadModalMask, setLoadModalMask] = useState(false);

  //Рефы
  const dateFromRef = useRef();
  const dateByRef = useRef();
  const paymentAmountRef = useRef();
  const reportRef = useRef();
  const BtnRef = useRef();
  const comparePeriodRef = useRef();
  const compareDayRef = useRef();
  const compareWeekRef = useRef();
  const compareMonthRef = useRef();
  const compareYearRef = useRef();

  // Сравнение по периодам -- состояния
  const [comparePeriod, setComparePeriod] = useState(false);
  const [compareDay, setCompareDay] = useState(false);
  const [compareWeek, setCompareWeek] = useState(false);
  const [compareMonth, setCompareMonth] = useState(false);
  const [compareYear, setCompareYear] = useState(false);
  // Создание массива
  const [compareDay_Data, setCompareDay_Data] = useState([]);
  const [compareWeek_Data, setCompareWeek_Data] = useState([]);
  const [compareMonth_Data, setCompareMonth_Data] = useState([]);
  const [compareYear_Data, setCompareYear_Data] = useState([]);
  const [compareData, setCompareData] = useState([]);
  const [compareDataColumns, setCompareDataColumns] = useState([]);
  const [maskCheck, setMaskCheck] = useState(false);
  // ----------------------------------------------

  //Переменные вывода
  //Маска расходов
  const [mask, setMask] = useState([]);
  // Маска по Описанию
  const [description, setDescription] = useState([]);
  // Маска получателя платежа
  const [paymentRecipient, setPaymentRecipient] = useState([]);
  // Маска дата С
  const [dateFrom, setDateFrom] = useState("2000-01-01");
  // Маска дата ПО
  const [dateBy, setDateBy] = useState(
    `${toDay.getFullYear()}-${+toDay.getMonth() + 1}-${toDay.getDate() < 10 ? "0" + toDay.getDate() : toDay.getDate()
    }`
  );
  // Маска Сумма платежа
  const [paymentAmount, setPaymentAmount] = useState([]);
  // Маска ИП/Кассы
  const [IpCash, setIpCash] = useState([]);
  // Маска Статусы платежа
  const [statusPay, setStatusPay] = useState([]);
  // Пост к выгрузке (Таблица)
  const [POST, setPOST] = useState([]);
  // Данные Всех Категорий для ипута
  const [isLoadDataExeles, setsILoadDataExeles] = useState(false);
  const [isLoadReport, setIsLoadReport] = useState(false);
  //Все данные из базы
  const [dats, setDats] = useState([]);
  const [dats_Two, setDats_Two] = useState([]);
  // Промежуточная переменная
  const [newdats, setNewdats] = useState([]);
  // Данные из базы эксель
  const [dataExeles, setDataExeles] = useState([]);
  // const [statics.data, setStatics] = useState([]);
  // Статистика по каждой из статей расходов
  const [dataStatistik, setDataStatistik] = useState([]);
  // ----------------------------------------------
  // Редаксы
  const [statics, dispatchStatics] = useReducer(tableBudget, { data: [] });
  const [excelesMask, dispatchExcelesMask] = useReducer(itemOfExpenditure, {
    data: [],
  });
  // ----------------------------------------------

  //Расчет текущего разрешения экрана
  const [Win_width, setWin_width] = useState(
    document.documentElement.clientWidth
  );
  window.addEventListener("resize", () =>
    setWin_width(document.documentElement.clientWidth)
  );
  //При изменении экрана менять стиль
  useEffect(() => {
    if (Win_width <= 768) {
      setTops("4.5rem");
      if (Win_width <= 540) {
        setTops("4rem");
      }
    } else {
      setTops("5rem");
    }
  }, [Win_width]);

  useEffect(() => {
    if (loadModalMask) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [loadModalMask]);

  // Склеивает массив для Пагинатора
  useEffect(() => {
    setPOST(dats_Two.slice(IndexofFirstPost, IndexofLastPost));
    BtnRef.current.scrollIntoView();
  }, [dats_Two, currentPage, IndexofFirstPost, IndexofLastPost]);

  // Фиксирует изменения нажатий на чекбоксы "Выбора по периодам"
  useEffect(() => {
    if (compareDay) {
      compareWeekRef.current.checked = false;
      compareMonthRef.current.checked = false;
      compareYearRef.current.checked = false;
      setCompareYear(false);
      setCompareMonth(false);
      setCompareWeek(false);
    }
    if (compareWeek) {
      compareDayRef.current.checked = false;
      compareMonthRef.current.checked = false;
      compareYearRef.current.checked = false;
      setCompareYear(false);
      setCompareMonth(false);
      setCompareDay(false);
    }
    if (compareMonth) {
      compareDayRef.current.checked = false;
      compareWeekRef.current.checked = false;
      compareYearRef.current.checked = false;
      setCompareYear(false);
      setCompareDay(false);
      setCompareWeek(false);
    }
    if (compareYear) {
      compareDayRef.current.checked = false;
      compareWeekRef.current.checked = false;
      compareMonthRef.current.checked = false;
      setCompareMonth(false);
      setCompareDay(false);
      setCompareWeek(false);
    }
  }, [compareDay, compareMonth, compareWeek, compareYear]);

  // Формирует новый массив данных заменив все Id категорий статей расходов на имена
  useEffect(() => {
    if (dats.length < 0 || isLoadReport) {
      setIsLoadReport(false);
      setShow_on(false);
    }
    if (dats.length > 0 && dataExeles.length > 0 && !isLoadReport) {
      let newDats = [];
      dats.map((el, index) => {
        const countExcel = dataExeles.filter(
          (item) =>
            +el.category == +item.categoryId ||
            (el.category !== null && item.categorySTR.trim() === el.category) ||
            el.category === item.categoryId
        );
        if (countExcel.length > 0) {
          countExcel.map((item) => {
            const newElement = {
              ...el,
              category: item.categorySTR,
              categoryId: item.categoryId,
            };
            newDats[index] = newElement;
          });
         
          return null;
        } else {
          newDats[index] = {
            ...el,
            category: el.category ?? "Пусто",
          };
        }
        if (typeof newDats[index].category === "number") {
          if (typeof el.category === "number") {
            newDats[index] = {
              ...el,
              category: "Установлена неизвестная категория №" + el.category,
            };
          }
          console.log('Yes')
        }
        return null;
      });
      setNewdats([]);
      setDats_Two(newDats.filter((el) => el !== undefined));
      setTimeout(() => {
        BtnRef.current.scrollIntoView();
        setIsLoadReport(true);
        setShow_on(false);
      }, 750);
    }
  }, [dataExeles, dats]);

  // Получает уникальные категории
  useEffect(() => {
    const newdata = [...new Set(dats_Two.map((el) => el.category))];
    setNewdats(newdata.filter((el) => el !== undefined));
  }, [dats_Two]);

  // Формирование таблицы всех статей расходов для вывода статистики по бюджету
  useEffect(() => {
    dispatchStatics({ type: CHANGE_TABLE_BUDGET, newdats: newdats });
  }, [newdats]);

  //Создание массива для вывода статистики по периодам
  useEffect(() => {
    if (!comparePeriod) {
      setCompareData([]);
    }
    if (comparePeriod && dats_Two.length > 0) {
      let Year = [];
      let Month = [];
      let Day = [];
      let Week = [];
      const DateByMonth = new Date(dats_Two[0].sheet_date.value).getMonth() + 1;
      const DateFromMonth =
        new Date(dats_Two[dats_Two.length - 1].sheet_date.value).getMonth() + 1;
      const DateByYear = new Date(dats_Two[0].sheet_date.value).getFullYear();
      const DateFromYear = new Date(
        dats_Two[dats_Two.length - 1].sheet_date.value
      ).getFullYear();

      const dayFunction = (dat, months, years) => {
        for (let days = 1; days <= dat; days++) {
          if (days == dat) {
            if (months !== 12) {
              Day.push({
                data: [...statics.data],
                periodBy: `${years}-${months}-${days}`,
                periodFrom: `${years}-${months + 1}-01`,
                title: `${years}-${months < 10 ? "0" + months : months}-${days < 10 ? "0" + days : days
                  }`,
              });
            } else {
              Day.push({
                data: [...statics.data],
                periodBy: `${years}-${months}-${days}`,
                periodFrom: `${years + 1}-01-01`,
                title: `${years}-${months < 10 ? "0" + months : months}-${days < 10 ? "0" + days : days
                  }`,
              });
            }
          } else {
            Day.push({
              data: [...statics.data],
              periodBy: `${years}-${months}-${days}`,
              periodFrom: `${years}-${months}-${days + 1}`,
              title: `${years}-${months < 10 ? "0" + months : months}-${days < 10 ? "0" + days : days
                }`,
            });
          }
        }
      };
      const WeekFunction = () => {
        const d2 = new Date(dats_Two[0].sheet_date.value).getTime();
        const d1 = new Date(
          dats_Two[dats_Two.length - 1].sheet_date.value
        ).getTime();
        const countWeek = Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
        let WeekDay = dats_Two[dats_Two.length - 1].sheet_date.value;
        for (let weeks = 0; weeks <= countWeek; weeks++) {
          const curr = new Date(WeekDay); // get current date
          const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
          const last = first + 7; // last day is the first day + 7
          const firstday = new Date(curr.setDate(first)); // 06-Jul-2014
          const lastday = new Date(curr.setDate(last)); //12-Jul-2014
          Week.push({
            data: [...statics.data],
            periodBy: `${firstday.getUTCFullYear()}-${firstday.getMonth() + 1
              }-${firstday.getDate()}`,
            periodFrom: `${lastday.getUTCFullYear()}-${lastday.getMonth() + 1
              }-${lastday.getDate()}`,
            title: `${firstday.getUTCFullYear()}-${firstday.getMonth() + 1 < 10
              ? "0" + (+firstday.getMonth() + 1)
              : +firstday.getMonth() + 1
              }-${firstday.getDate() < 10
                ? "0" + firstday.getDate()
                : firstday.getDate()
              } \n—${lastday.getUTCFullYear()}-${lastday.getMonth() + 1 < 10
                ? "0" + (+lastday.getMonth() + 1)
                : lastday.getMonth() + 1
              }-${lastday.getDate() < 10
                ? "0" + lastday.getDate()
                : lastday.getDate()
              }`,
          });
          WeekDay = `${lastday.getUTCFullYear()}-${lastday.getMonth() + 1
            }-${lastday.getDate()}`;
        }
      };
      WeekFunction();
      for (let years = +DateFromYear; years <= +DateByYear; years++) {
        Year.push({
          periodBy: `${years}-01-01`,
          periodFrom: `${years}-12-31`,
          data: [...statics.data],
          title: `${years}`,
        });

        if (DateByYear === DateFromYear) {
          for (let months = DateFromMonth; months <= DateByMonth; months++) {
            const dat = 32 - new Date(years, months - 1, 32).getDate();
            Month.push({
              data: [...statics.data],
              periodBy: `${years}-${months}-01`,
              periodFrom:
                months + 1 > 12
                  ? `${years + 1}-01-01`
                  : `${years}-${months + 1}-01`,
              title: `${years}-${months < 10 ? "0" + months : months}`,
            });
            dayFunction(dat, months, years);
          }
        } else {
          if (years === DateFromYear) {
            for (let months = DateFromMonth; months <= 12; months++) {
              const dat = 32 - new Date(years, months - 1, 32).getDate();
              Month.push({
                data: [...statics.data],
                periodBy: `${years}-${months}-01`,
                periodFrom:
                  months + 1 > 12
                    ? `${years + 1}-01-01`
                    : `${years}-${months + 1}-01`,
                title: `${years}-${months < 10 ? "0" + months : months}`,
              });
              dayFunction(dat, months, years);
            }
          }
          if (years < DateByYear && years > DateFromYear) {
            for (let months = 1; months <= 12; months++) {
              const dat = 32 - new Date(years, months - 1, 32).getDate();
              Month.push({
                data: [...statics.data],
                periodBy: `${years}-${months}-01`,
                periodFrom:
                  months + 1 > 12
                    ? `${years + 1}-01-01`
                    : `${years}-${months + 1}-01`,
                title: `${years}-${months < 10 ? "0" + months : months}`,
              });
              dayFunction(dat, months, years);
            }
          }
          if (years === DateByYear) {
            for (let months = 1; months <= DateByMonth; months++) {
              const dat = 32 - new Date(years, months - 1, 32).getDate();
              Month.push({
                data: [...statics.data],
                periodBy: `${years}-${months}-01`,
                periodFrom:
                  months + 1 > 12
                    ? `${years + 1}-01-01`
                    : `${years}-${months + 1}-01`,
                title: `${years}-${months < 10 ? "0" + months : months}`,
              });
              dayFunction(dat, months, years);
            }
          }
        }
      }
      setCompareYear_Data(Year);
      setCompareMonth_Data(Month);
      setCompareDay_Data(Day);
      setCompareWeek_Data(Week);
    }
  }, [dats_Two, comparePeriod]);

  // Формирование таблицы по периодам
  const dataGeneration = async (pastData) => {
    let newYear_Data = [];
    let newDataCompare = [];
    await setTimeout(() => {
      pastData.map((item) => {
        const filterYear = dats_Two.filter(
          (el) =>
            new Date(el.sheet_date.value).getTime() <
            new Date(item.periodFrom).getTime() &&
            new Date(el.sheet_date.value).getTime() >=
            new Date(item.periodBy).getTime()
        );
        const newElem = item.data.map((el) => {
          let newamount = 0;
          const newdats = filterYear.filter(
            (element) => typeof element.category === 'number' ? "Категория неизвестная. №" + element.category :
              element.category &&
              element.category.toLowerCase().startsWith(el.data.toLowerCase())
          );
          newdats.map((el) => {
            newamount =
              newamount + (+el.confirmed_amount ? +el.confirmed_amount : 0);
          });
          return { ...el, amount: +newamount.toFixed(2) };
        });
        newYear_Data.push({ ...item, data: newElem });
      });
      dataStatistik.map((item) => {
        let amounts = [];
        newYear_Data.map((el) => {
          el.data.map((elem) => {
            if (item.data === elem.data) {
              amounts.push(elem.amount);
            }
          });
        });
        newDataCompare.push({ item: item.data, allamount: amounts });
      });
    }, 500);
    await setCompareData(newYear_Data);
    await setCompareDataColumns(newDataCompare);
  };
  useEffect(() => {
    if (!compareYear && !compareMonth && !compareWeek && !compareDay) {
      setCompareData([]);
      setCompareDataColumns([]);
    }
  }, [compareYear, compareMonth, compareWeek, compareDay]);

  // Если данные загружены снимает блок с чекбокса
  useEffect(() => {
    if (dats_Two.length > 0) {
      comparePeriodRef.current.disabled = false;
    }
  }, [dats_Two]);

  // Формирование подсчета суммы по статьям расходов
  useEffect(() => {
    async function newEl() {
      let newStat = [];
      await statics.data.map(async (item, index) => {
        let newamount = 0;
        const newdats = dats_Two.filter(
          (el) => typeof el.category === 'number' ? "Категория неизвестная. №" + el.category :
            el.category &&
            el.category.toLowerCase().startsWith(item.data.toLowerCase())
        );
        newdats.map((el) => {
          newamount =
            newamount + (+el.confirmed_amount ? +el.confirmed_amount : 0);
        });
        newStat[newStat.length + 1] = {
          data: item.data ? item.data : "Пусто",
          amount: +newamount ? newamount : 0,
          upperLevel: item.upperLevel ? item.upperLevel : false,
        };
      });
      setDataStatistik([...newStat.filter((el) => el !== null)]);
    }
    newEl();
  }, [statics.data]);

  //При обновлении запроса задает 1 страницу и переводит на элемент таблицы
  useEffect(() => {
    setcurrentPage(1);
    BtnRef.current.scrollIntoView();
  }, [dats_Two]);

  // Номер страницы
  const paginete = (pageNumber) => {
    setcurrentPage(pageNumber);
  };

  //Загрузка данных из Exele таблицы
  useEffect(() => {
    if (dataExeles.length > 0) {
      setShow_on(false);
    } else {
      setAlertText("Подождите загрузка данных");
      setShow_on(true);
    }
    if (!isLoadDataExeles) {
      fetch("https://payments.lukino.ru/api/budget",
      // fetch("http://localhost:4000/api/budget",
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          method: "POST",
          body: JSON.stringify({}),
        })
        .then((resp) => resp.json())
        .then((res) => {
          setDataExeles(res);
        })
        .then(() => setsILoadDataExeles(true));
    }
  }, [dataExeles, isLoadDataExeles]);

  //Обновление Данных - Синхронизация с таблицей
  useEffect(() => {
    dispatchExcelesMask({ type: ADD_EXPENDITURE, elements: dataExeles });
  }, [dataExeles]);

  // Валидация формы для вывода
  async function Validation() {
    setComparePeriod(false);
    setCompareMonth(false);
    setCompareDay(false);
    setCompareWeek(false);
    setCompareYear(false);
    comparePeriodRef.current.checked = false;
    if (dateFrom >= dateBy) {
      setAlertText("Не корректный ввод даты");
      setShow_on(!show_on);
      setTimeout(() => {
        setShow_on(false);
        dateFromRef.current.focus();
      }, 1500);
    } else {
      let posts = "";
      function dates() {
        posts += ` sheet_date BETWEEN '${dateFrom}' AND '${dateBy}' `;
      }
      await dates();
      await mask.map((item, index) => {
        if (mask.length > 0) {
          index === 0 &&
            (posts += `AND (${item.dataId ? ` category = ${item.dataId} ` : ""
              } OR `);
          index === mask.length - 1 &&
            (posts += `${item.dataId ? ` category = ${item.dataId} ` : ""}) `);
          index > 0 &&
            index < mask.length - 1 &&
            (posts += `${item.dataId ? ` category = ${item.dataId} ` : ""
              } OR `);
        } else {
          posts += `AND (${item.dataId ? ` category = ${item.dataId} ` : ""}) `;
        }
        return null;
      });
      await paymentAmount.map((item, index) => {
        if (paymentAmount.length > 1) {
          index === 0 && (posts += `AND ( confirmed_amount= ${item.data} OR `);
          index === paymentAmount.length - 1 &&
            (posts += `confirmed_amount = ${item.data} ) `);
          index > 0 &&
            index < paymentAmount.length - 1 &&
            (posts += `confirmed_amount= ${item.data} OR `);
        } else {
          posts += `AND confirmed_amount= ${item.data} `;
        }
        return null;
      });

      await description.map((item, index) => {
        if (description.length > 1) {
          index === 0 &&
            (posts += `AND ( LOWER(description) LIKE '%${item.data.toLowerCase()}%' OR `);
          index === description.length - 1 &&
            (posts += `LOWER(description)  LIKE '%${item.data.toLowerCase()}%' ) `);
          index > 0 &&
            index < description.length - 1 &&
            (posts += `LOWER(description) LIKE '%${item.data.toLowerCase()}%' OR `);
        } else {
          posts += `AND LOWER(description) LIKE '%${item.data.toLowerCase()}%' `;
        }
        return null;
      });
      await paymentRecipient.map((item, index) => {
        if (paymentRecipient.length > 1) {
          index === 0 &&
            (posts += `AND ( LOWER(payee)  LIKE '%${item.data.toLowerCase()}%' OR `);
          index === paymentRecipient.length - 1 &&
            (posts += `LOWER(payee)  LIKE '%${item.data.toLowerCase()}%' ) `);
          index > 0 &&
            index < paymentRecipient.length - 1 &&
            (posts += `LOWER(payee)  LIKE '%${item.data.toLowerCase()}%' OR `);
        } else {
          posts += `AND LOWER(payee)  LIKE '%${item.data.toLowerCase()}%' `;
        }
        return null;
      });
      await IpCash.map((item, index) => {
        if (IpCash.length > 1) {
          index === 0 &&
            (posts += `AND ( account_cashdesk= '${item.data}' OR `);
          index === IpCash.length - 1 &&
            (posts += `account_cashdesk = '${item.data}' ) `);
          index > 0 &&
            index < IpCash.length - 1 &&
            (posts += `account_cashdesk= '${item.data}' OR `);
        } else {
          posts += `AND account_cashdesk= '${item.data}' `;
        }
        return null;
      });
      await statusPay.map((item, index) => {
        if (statusPay.length > 1) {
          index === 0 &&
            (posts += `AND ( LOWER(status) = '${item.data.toLowerCase()}' OR `);
          index === statusPay.length - 1 &&
            (posts += `LOWER(status) = '${item.data.toLowerCase()}' ) `);
          index > 0 &&
            index < statusPay.length - 1 &&
            (posts += `LOWER(status) = '${item.data.toLowerCase()}' OR `);
        } else {
          posts += `AND LOWER(status) = '${item.data.toLowerCase()}' `;
        }
        return null;
      });
      const POST1 = await { post: posts };
      async function loads() {
        setDats([]);
        fetch(
        "https://payments.lukino.ru/api/bigquery",
        // fetch("http://localhost:4000/api/Bigquery",
          {
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            method: "POST",
            body: JSON.stringify(POST1),
          })
          .then((response) => response.json())
          .then((res) => {
            setDats(res);
            if (res.length == 0) {
              setIsLoadingReport(false);
              comparePeriodRef.current.disabled = true;
              setAlertText("Ничего не найдено!");
              setShow_on(!show_on);
              setTimeout(() => {
                setShow_on(false);
              }, 1500);
            }
          });
      }
      await loads().then(LoadTable).then(setIsLoadingReport(true));
    }
  }

  //Добавление нового элемента в Элементы фильтра
  const NewItems = (item, name_arr) => {
    let proverka = false;
    if (name_arr === "description") {
      if (description.length > 0) {
        proverka =
          description.filter((items) => items.data === item).length >= 1;
        if (!proverka) {
          const newDescription = {
            id: description.length + 1,
            data: item,
            active: true,
          };
          setDescription([...description, newDescription]);
        } else {
          setAlertText("Данное значение уже внесено в фильтр");
          setShow_on(!show_on);
          setTimeout(() => {
            setShow_on(false);
          }, 1500);
        }
      } else {
        const newDescription = {
          id: description.length + 1,
          data: item,
          active: true,
        };
        setDescription([newDescription]);
      }
    }
    if (name_arr === "paymentRecipient") {
      proverka =
        paymentRecipient.filter((items) => items.data === item).length >= 1;
      if (paymentRecipient.length > 0) {
        proverka =
          paymentRecipient.filter((items) => items.data === item).length >= 1;
        if (!proverka) {
          const newPaymentRecipient = {
            id: paymentRecipient.length + 1,
            data: item,
            active: true,
          };
          setPaymentRecipient([...paymentRecipient, newPaymentRecipient]);
        } else {
          setAlertText("Данное значение уже внесено в фильтр");
          setShow_on(!show_on);
          setTimeout(() => {
            setShow_on(false);
          }, 1500);
        }
      } else {
        const newPaymentRecipient = {
          id: paymentRecipient.length + 1,
          data: item,
          active: true,
        };
        setPaymentRecipient([newPaymentRecipient]);
      }
    }
    if (name_arr === "paymentAmount") {
      proverka =
        paymentAmount.filter((items) => items.data === item).length >= 1;
      if (paymentAmount.length > 0) {
        proverka =
          paymentAmount.filter((items) => items.data === item).length >= 1;
        if (!proverka) {
          const newPaymentAmount = {
            id: paymentAmount.length + 1,
            data: item,
            active: true,
          };
          setPaymentAmount([...paymentAmount, newPaymentAmount]);
        } else {
          setAlertText("Данное значение уже внесено в фильтр");
          setShow_on(!show_on);
          setTimeout(() => {
            setShow_on(false);
          }, 1500);
        }
      } else {
        const newPaymentAmount = {
          id: paymentAmount.length + 1,
          data: item,
          active: true,
        };
        setPaymentAmount([newPaymentAmount]);
      }
    }
  };

  //Функции удаления элементов поиска
  const DelItems = (id, name_arr) => {
    if (name_arr === "description") {
      const newDescription = description.filter((item) => item.id !== id);
      setDescription(newDescription);
    }
    if (name_arr === "paymentRecipient") {
      const newPaymentRecipient = paymentRecipient.filter(
        (item) => item.id !== id
      );
      setPaymentRecipient(newPaymentRecipient);
    }
    if (name_arr === "paymentAmount") {
      const newPaymentAmount = paymentAmount.filter((item) => item.id !== id);
      setPaymentAmount(newPaymentAmount);
    }
  };

  // Сообщение о загрузке
  const LoadTable = () => {
    if (!isLoadReport) {
      setAlertText("Загрузка, пожалуйста подождите...");
      setShow_on(!show_on);
    } else {
      setShow_on(false);
    }
  };

  //Данные для вывода
  const value = {
    paginete,
    currentPage,
    postPerPage,
    Win_width,
    DelItems,
    NewItems,
    Validation,
    dataExeles,
    dats,
    isLoadDataExeles,
    POST,
    statusPay,
    IpCash,
    paymentAmount,
    dateBy,
    dateFrom,
    paymentRecipient,
    description,
    mask,
    reportRef,
    paymentAmountRef,
    dateByRef,
    dateFromRef,
    isLoadingReport,
    tops,
    show_on,
    AlertText,
    toDay,
    setPostPerPage,
    setDateFrom,
    setDateBy,
    setIpCash,
    setStatusPay,
    isLoadReport,
    BtnRef,
    setMask,
    dats_Two,
    dataStatistik,
    comparePeriodRef,
    comparePeriod,
    setComparePeriod,
    compareDayRef,
    compareYearRef,
    compareWeekRef,
    compareMonthRef,
    setCompareYear,
    setCompareDay,
    setCompareMonth,
    setCompareWeek,
    setMaskCheck,
    maskCheck,
    compareData,
    compareDataColumns,
    loadModalMask,
    setLoadModalMask,
    dispatchExcelesMask,
    CHANGE_EXPENDITURE,
    REMOVE_EXPENDITURE,
    excelesMask,
    UPDATE_EXPENDITURE,
    dataGeneration,
    setAlertText,
    setShow_on,
    compareYear_Data,
    compareMonth_Data,
    compareDay_Data,
    compareWeek_Data,
  };

  return (
    <CostomContext.Provider value={value}>
      {props.children}
    </CostomContext.Provider>
  );
};
